import {
  useQuery,
} from 'react-query';

import middleman from 'helpers/middleman';

export const usePortfolioFiles = (portfolioUuid: string, tags: string[] = []) => useQuery(['portfolioFiles', portfolioUuid, tags], () => {
  return middleman.get(`/portfolio/files/${portfolioUuid}${tags.length > 0 ? `?tags=${tags.join(',')}` : ''}`).then(response => response.data);
}, {
  enabled: !!portfolioUuid,
});