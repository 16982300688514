import React from 'react';
import Documents from '@hubins/components/Documents';
import PrivateDocuments from '@hubins/components/Documents/PrivateDocuments';
import Heading from '@hubins/components/Heading';
import __ from 'localisation';
import { Columns, Column } from 'components/Columns';
import PortfolioFiles from '@hubins/components/PortfolioFiles/PortfolioFiles';

const ClientDocuments = ({ portfolioUuid, profileUuid }) => {

  return (
    <Columns>
      <Column m="6">
        <Documents
          title={__('documents_terms')}
          tags=''
          path='terms'
          languageSupport={true}
          compact
        />
      </Column>
      <Column m="6">
        <Heading size="3" className="s-top-m s-bottom-m">
          {__('reports')}
        </Heading>
        <PortfolioFiles portfolioUuid={portfolioUuid} tags={["report"]} />
        <PrivateDocuments
          title={__('your_documents')}
          compact
          portfolioUuid={portfolioUuid}
          profileUuid={profileUuid}
        />
        <PrivateDocuments
          compact
          portfolio
          portfolioUuid={portfolioUuid}
        />
      </Column>
    </Columns>
  );

};

export default ClientDocuments;
