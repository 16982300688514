import React from 'react';
import { usePortfolioFiles } from 'queries/portfolioFiles';
import { LoadingBar } from '@hubins/components/LoadingIndicators';
import AuthDownloadLink from '@hubins/components/AuthDownloadLink/AuthDownloadLink';
import SimpleTable from 'modules/Tables/SimpleTable';

const loadingObj = {
    name: '',
};

const loadingState = [
    loadingObj,
    loadingObj,
    loadingObj,
    loadingObj,
];


const downloadUrl = (portfolioUuid, fileUuid) => `/portfolio/files/download/${portfolioUuid}/${fileUuid}`;
const PortfolioFiles = ({ portfolioUuid, tags = [] }) => {
    const { data, isLoading, error } = usePortfolioFiles(portfolioUuid, tags);
    const headers = [
        {
            Header: '',
            accessor: 'download',
            width: 10,
            Cell: ({ row }) => (
                <>
                    {isLoading
                        ? <LoadingBar small />
                        : <AuthDownloadLink
                            url={downloadUrl(portfolioUuid, row.original.uuid)}
                            filename={row.original.name}
                            onlyIcon={true}
                            icon="Download Bottom by Streamlinehq"
                        />}
                </>
            )
        },
        {
            Header: '',
            accessor: 'name',
        },
    ]

    return (
        <SimpleTable
            headers={headers}
            data={isLoading ? loadingState : data || []}
        />
    );
};

export default PortfolioFiles;