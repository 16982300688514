import './polyfills';
import { Provider } from 'react-redux';
import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import configureStore from './state/store';
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  environment: import.meta.env.VITE_MODE || 'local',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0,
  tracePropagationTargets: ["localhost", /^https:\/\/back\.hubins\.com/],
  // Session Replay
  // replaysSessionSampleRate: 0.1,
  // replaysOnErrorSampleRate: 1.0,
});

const reduxStore = configureStore();
const container = document.getElementById('app');
const root = createRoot(container);
root.render(
    <Provider store={reduxStore}>
      <App />
    </Provider>
);
